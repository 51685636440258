// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pump-js": () => import("/opt/build/repo/src/templates/pump.js" /* webpackChunkName: "component---src-templates-pump-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analytics-js": () => import("/opt/build/repo/src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-beta-js": () => import("/opt/build/repo/src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-checkout-js": () => import("/opt/build/repo/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dashboard-create-tickets-js": () => import("/opt/build/repo/src/pages/dashboard/create-tickets.js" /* webpackChunkName: "component---src-pages-dashboard-create-tickets-js" */),
  "component---src-pages-dashboard-issue-tickets-js": () => import("/opt/build/repo/src/pages/dashboard/issue-tickets.js" /* webpackChunkName: "component---src-pages-dashboard-issue-tickets-js" */),
  "component---src-pages-dashboard-manage-page-js": () => import("/opt/build/repo/src/pages/dashboard/manage-page.js" /* webpackChunkName: "component---src-pages-dashboard-manage-page-js" */),
  "component---src-pages-dashboard-overview-js": () => import("/opt/build/repo/src/pages/dashboard/overview.js" /* webpackChunkName: "component---src-pages-dashboard-overview-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-add-js": () => import("/opt/build/repo/src/pages/events/add.js" /* webpackChunkName: "component---src-pages-events-add-js" */),
  "component---src-pages-file-upload-js": () => import("/opt/build/repo/src/pages/file-upload.js" /* webpackChunkName: "component---src-pages-file-upload-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-tickets-js": () => import("/opt/build/repo/src/pages/my-tickets.js" /* webpackChunkName: "component---src-pages-my-tickets-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-payment-success-js": () => import("/opt/build/repo/src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-test-add-card-js": () => import("/opt/build/repo/src/pages/test-add-card.js" /* webpackChunkName: "component---src-pages-test-add-card-js" */),
  "component---src-pages-tickets-js": () => import("/opt/build/repo/src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

